.headerWrapper,
#content-description,
.bonusBlock,
footer {
  display: none;
}

.headerWrapper ~ #content {
  padding-top: 0;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  line-height: 1.4em;
  color: #717171;
}

input {
  &:not([type=checkbox]) {
    -webkit-appearance: none !important;
    appearance: none !important;
    box-shadow: none;
    border: none;
    display: block;
	
  }
  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
    -webkit-appearance: none;
    appearance: none;
  }
  
  &:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset;
  }
}

.force-show {
      -webkit-appearance: auto !important;
    appearance: auto !important;
}

.input-group {
  display: block;
  margin-bottom: 55px;

  input {
    color: #19325d;
    padding: 6px 24px 16px 6px;
    border-bottom: 2px solid rgba(6, 28, 51, 0.25);
    font-size: 20px;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.5s ease;

    &:focus {
      border-color: rgba(25, 50, 93, 0.5);
    }
  }
}

.form-check {
  display: block;
  margin-bottom: 55px;
}

a {
  text-decoration: none;
  transition: all 0.5s ease;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

hr {
  border-top: 6px solid #a8a8a8;
  max-width: 15%;
  margin-left: 0;
}

.btn {
  background-color: transparent;
  padding: 12px 6px;
  font-size: 14px;
  text-transform: uppercase;
  box-sizing: border-box;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  width: 100%;
  margin-bottom: 12px;
  transition: all 0.5s ease;

  &.btn-orange {
    background-color: #ff7319;
    border: 2px solid #ff7319;
    color: #fff;
    font-weight: bold !important;

    &:hover {
      background-color: #df6b23;
      border: 2px solid #df6b23;
      color: #fff;
      font-weight: bold !important;
    }
  }

  &.btn-orange-alt {
    background-color: #fff;
    border: 2px solid #ff7319;
    color: #ff7319;
    font-weight: bold !important;

    &:hover {
      background-color: #df6b23;
      border: 2px solid #df6b23;
      color: #fff;
      font-weight: bold !important;
    }
  }
}

.btn-block + .btn-block {
  margin-top: 15px;
}

/*text*/
.text-navy {
  color: #19325d;
}

.text-orange {
  color: #ff7319;
}

.text-muted {
  color: #a8a8a8;
}

.text-white {
  color: #ffffff;
}

.display-on-img {
  font-weight: bold;
  margin: auto;
  font-size: 55px;
  line-height: 55px;

  @media (min-width: 992px) {
    font-size: 70px;
    line-height: 75px;
  }

  @media (min-width: 1200px) {
    font-size: 95px;
    line-height: 85px;
  }
}

.login_container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media (min-width: 768px) {
    flex-direction: row;
    height: 100px;
  }
}

.error_message {
  border-radius: 2px;
  margin-top: 30px;
  box-shadow: 0px 1px 5px #81818199;
  color: #ee2424;

  p {
    margin: 10px;
  }

  ul {
    padding-left: 10px;
    padding-right: 10px;

    li {
      list-style-type: none;

      &:before {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: "liga";
        font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
        content: "error_outline";
        vertical-align: middle;
        padding-right: 5px;
      }
    }
  }
}

.login_img {
  background: url(/resources/images/log-in-back-image-color.jpg) no-repeat
    center / cover;
  position: relative;
  max-width: 100%;
  padding-top: 60px;
  padding-left: 10%;
  padding-right: 10%;
  background-position-y: top;

  @media (min-width: 530px) {
    padding-top: 165px;
    padding-left: 15%;
    padding-right: 15%;
  }

  @media (min-width: 768px) {
    flex: 50%;
  }

  @media (min-width: 1200px) {
    flex: 55%;
  }

  @media (min-width: 1400px) {
    flex: 62%;
  }

  .login_img_logo {
    position: relative;
    top: auto;
    left: auto;
    transform: translateY(-30%);

    @media (min-width: 530px) {
      transform: translateY(-80%);
    }

    @media (min-width: 768px) {
      position: absolute;
      top: 14%;
      left: 10%;
      transform: translateY(-50%);
    }

    img {
      max-height: 100%;

      @media (min-width: 768px) {
        max-height: 45px;
      }

      @media (min-width: 992px) {
        max-height: 54px;
      }
    }
  }
}

.login_img_slogan {
  position: absolute;
  bottom: 10%;
  left: 10%;
  transform: translateY(-50%);
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.login_card {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 100%;
  padding: 24px 10%;
  flex: 100%;

  @media (min-width: 530px) {
    padding: 60px 15% 24px 15%;
  }

  @media (min-width: 768px) {
    padding: 85px 55px 0 55px;
    flex: 50%;
  }

  @media (min-width: 992px) {
    padding: 85px 85px 0 85px;
  }

  @media (min-width: 1200px) {
    padding: 85px 95px 0 95px;
    flex: 45%;
  }

  @media (min-width: 1400px) {
    padding: 85px 100px 0 100px;
    flex: 38%;
  }

  .login_img_logo img {
    max-height: 50px;
  }
}

.login_card_header {
  p.h2 {
    text-align: left;
  }

  &__title {
    display: flex;
    align-items: center;

    h2 {
      margin: 0;
      padding-right: 20px;
    }
  }

  &__tooltip-btn {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #19325d;
  }
}

.login_card_form {
  margin-top: 50px;
  width: 100%;

  img {
    position: absolute;
    right: 0;
    bottom: 18px;
    padding: 0px 5px;
    transition: 0.3s;
    height: 20px;
  }
}

.login_card_footer {
  position: -webkit-sticky;
  position: sticky;
  top: 90%;
  margin-top: 4em;
  color: #19325d;
  display: flex;
  justify-content: center;

  p {
    line-height: 1.6em;
  }
}

.login_card_details {
  text-align: left;
  line-height: 20px;
}

.text-underline {
  text-decoration: underline !important;
}
